import Logo from '../assets/images/logo.png';
import chef_of_ceylon_staff from "../assets/chef_of_ceylon_staff.jpg"
import { PhotoGallery } from '../components/PhotoGallery'
import { Link } from "react-router-dom"

export const Home = () => {
    return (
        <div className="home col-12">
            <div className="banner">
                <div className="banner-text">
                <img className='banner-logo' src={Logo}/>
                <h4>Cooking is one of the greatest gifts.</h4>
                </div>
            </div>
            <div className="about">
                <div className="cop-26 col-6">
                    <div className="flag-container sp col-11">
                        <p>Official Caterer for Sri Lankan President's Delegation at COP26 Conference -Glasgow.</p> 
                    </div>
                </div>
                <div className="about-us col-6">
                    <h3>Who we are?</h3>
                    Chef of Ceylon is a Homemade food catering service, registered with Glasgow City Council and undertakes orders for traditional mouth-watering Sri Lankan food you would have never tasted before in Scotland.
                    All orders should placed on previous day before 8.00pm to buy and cook from scratch.
                    Please visit our <a href="https://www.facebook.com/ChefofCeylon/" target="_blank" className='fb'>Facebook</a> page for weekly promotional offers.
                </div>
            </div>


            <div className="gallery-container">
                <img src={chef_of_ceylon_staff} alt="" />
            </div>


            <div className="gallery-container">
                <div className="text-container col-4">
                    <h3>More Photos..?</h3>
                    <p><Link className="gal" to="/gallery" >Click Here</Link> to view our Photo gallery</p>
                </div>
                <div className='image-holder col-8'> 
                    <div className="image-container col-12">
                        <PhotoGallery />

                    </div>
                </div>                   
            </div>


            

            


        </div>
    )
}