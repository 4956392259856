import { Link } from 'react-router-dom'

export const OrderButton = (props:any) => {

    const days_arr = [   
        { day: "Monday", day_num:1 },            
        { day: "Tuesday", day_num:2 },
        { day: "Wednesday", day_num:3 },
        { day: "Thursday", day_num:4 },
        { day: "Friday", day_num:5 }
    ]

    function getDateName(day_num:number){     
        var valObj = days_arr.filter(function(elem){
            if(elem.day_num == day_num) return elem.day;
        });   
        return valObj[0].day;    
    }


    return <Link className="menu-item" to={`/products/${props.itm.date}/${props.itm.week_day}`}>{props.itm.date} - {getDateName(props.itm.week_day)}</Link>


}