import React, { useState, useEffect } from 'react'
import API from '../networking/api'
import { OrderButton } from '../components/OrderButton'

type Props = {
    is_active: boolean
};


export const Calender: React.FC<Props> = ({is_active}) => {

    const [businessDays, setBusinessDays] = useState([]);

    const [isActive, setActive] = useState(is_active);
    const toggleMe = () => {
        setActive(!isActive);
    }

    useEffect(() => {

        API.get(`utils/get-business-days`)
        .then(res => {
            console.log(res);
            console.log(res.data);
            setBusinessDays(res.data.business_days);
        }).catch(err => {
            console.log(err);
        });
        
        
    
    }, [])

    return (
        <div className={isActive ? 'calender-container active-calender': 'calender-container'}>
            <div className="toggle-button-container">
               <div className="toggle" onClick={toggleMe}>
                    <span className='l'></span>
                    <span className='r'></span>
               </div> 
            </div>
            <div className="calender-flag" onClick={toggleMe}>
                Select Your Order Date
            </div>
            <div className="days">
                <ul>
                    {businessDays.map((itm:any,i) => (
                        <li onClick={toggleMe} key={i}><OrderButton key={i} itm={itm} /></li>
                    ))}
                </ul>
            </div>
        </div>
    )
}