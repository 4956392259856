import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'

type Props = {
    is_active: boolean
};

export const MobileMenu: React.FC<Props> = ({is_active}) => {

    const [isActive, setActive] = useState(is_active);
    const toggleMe = () => {
        setActive(!isActive);
    }

   
    useEffect(() => {     
       setActive(!isActive);
    }, [is_active])

    return (
        <div className={isActive ? 'mobile-menu-container active-menu': 'mobile-menu-container'} >
            <div className="toggle-button-container">
               <div className="toggle" onClick={toggleMe}>
                    <span className='l'></span>
                    <span className='r'></span>
               </div> 
            </div>
            <div className="menu-items">
                <Link onClick={toggleMe} className="menu-item cart" to="/cart" >Your Cart</Link>
                <Link onClick={toggleMe} className="menu-item main" to="/" >Home</Link> 
                <Link onClick={toggleMe} className="menu-item" to="/reviews" >Reviews</Link> 
                <Link onClick={toggleMe} className="menu-item" to="/delivery" >Delivery</Link> 
                <Link onClick={toggleMe} className="menu-item" to="/gallery" >Gallery</Link> 
                <Link onClick={toggleMe} className="menu-item" to="/contact" >Contact</Link>
 
            </div>
        </div>
    )

}