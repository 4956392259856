
import { useState, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'


// images
//import Logo from './assets/images/logo.png'

// components
import { Navbar } from './components/Navbar'
import { RightPanel } from './components/RightPanel'
import { Calender } from './components/Calender'
import { PreLoader } from './components/PreLoader'
import { Footer } from './components/Footer'
import { CloseMessage } from './components/CloseMessage'



// pages
import { Home } from './pages/Home'
import { Cart } from './pages/Cart'
import { Reviews } from './pages/Reviews'
import { Delivery } from './pages/Delivery'
import { Gallery } from './pages/Gallery'
import { Contact } from './pages/Contact'
import Products from './pages/Products'
import { Checkout } from './pages/Checkout'
import { Peyments }  from './pages/Payments'
import { Login }  from './pages/Login'
import { Register }  from './pages/Register'
import { SuccessOrder }  from './pages/SuccessOrder'
import { ErrorOrder }  from './pages/ErrorOrder'
import { ErrorPayment }  from './pages/ErrorPayment'

import { useDispatch, useSelector } from "react-redux"
//import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { cartActions } from "./store/cart_slice"


import { useSpring, animated } from "react-spring";


function App() {
  
  //const isLoggedIn = useSelector((state:any) => state.auth.isLoggedIn);
  const cart = useSelector((state:any) => { return state.cart});
  const address = useSelector((state:any) => { return state.address});
  const loader = useSelector((state:any) => { return state.loader});

  const [calender, setCalender] = useState(false);

  // const showMobileMenu = () => {
  //   setCalender(!calender);
  // }

  // const dispatch = useDispatch();
  // useEffect(() => {  
  //   dispatch(cartActions.clearCart());
  // }, [])

  useEffect(() => {   
    localStorage.setItem('address',JSON.stringify(address));
  }, [address])

  useEffect(() => {  
    localStorage.setItem('cart',JSON.stringify(cart));
  }, [cart])

  //console.log(import.meta.env.VITE_SOME_KEY);
  const PAY_PAL_KEY = process.env.REACT_APP_PAYPAL_CLIENT_ID

  const contentWrapper = useSpring({
    delay: 300,
    from: { opacity: "0%" },
    to: { opacity: "100%" },
  });

  return (

    <div className="App">
      <div className="container col-12">
        
          {
            loader.isLoading &&
            <PreLoader />
          }
         
          <main className="col-9">
            <CloseMessage />
            <Navbar />
            <animated.div className="router-container" style={contentWrapper}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/reviews" element={<Reviews />} />
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/gallery" element={<Gallery />} />          
              <Route path="/contact" element={<Contact />} />
              <Route path="/payments" element={<Peyments />} />
              <Route path="/products/:day/:week" element={<Products />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/success_order" element={<SuccessOrder />} />
              <Route path="/error_order" element={<ErrorOrder />} />
              <Route path="/error_payment" element={<ErrorPayment />} />
            </Routes>
            </animated.div>

            <Footer />
          </main>

          

          <RightPanel />
          <Calender is_active={calender}/>

          
        
          
      </div>

      
      
      
     


    </div>
    // </PayPalScriptProvider>
  )
}

export default App

