//import { actionCreators, State } from '../state/'
import { useDispatch, useSelector } from "react-redux"
import { GiRoundStar } from "react-icons/gi"
import review from '../assets/reviews.json'

export const Reviews = () => {

    //const state = useSelector((state: State) => state.cart)
    const cart = useSelector((state:any) => { return state.cart});

    // const cart2 = localStorage.getItem('cart');

    // return <p>{JSON.stringify(cart2)}</p>
    let stars = []
    for (let i = 0; i < 5; ++i) {
        stars.push(<GiRoundStar className="rating-star" key={i} />)
    }

    return (
        <section className="checkout-container">
            <div className="checkout-header col-12">
                <div className="cart-head">
                    <h2>REVIEWS</h2>
                    <div className="total"></div>
                    {/* <div>Name : {review.name}</div> */}
                    {/* {review.country.map((country) => {
                        return <option key={country.id} value={country.id}>{country.name}</option>
                    })} */}
                </div>
            </div>

            <div className="col-12">

                {review.data.map((review:any,i) => (   
                <div className="review-display-div">
                    <div className="col-12">
                        <p className="rev-comment">"{review.remark}"</p>
                        
                        <p className="name">
                            <div>
                                <h2>{stars}</h2>
                            </div>                  
                            <b>-- {review.name} --</b>
                        </p>
                    </div>               
                </div>
                ))
            }


            </div>


        </section>
    )        
}