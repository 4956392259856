export const CloseMessage = () => {



    return (

        <>
            <p className="close-message">

            Business is closed until further notice. Sorry for any inconvenience.

            </p>
        
        </>
    )
}