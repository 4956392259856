import * as Yup from 'yup';

const schema = {
    name: Yup.string().required('Name is a required field.') ,
    contact_no: Yup.string().required('Contact Number is a required field.'), 
    email: Yup.string().email('Email is invalid'),
    address_line1: Yup.string().required('Address Line1 is a required field.'), 
    postcode: Yup.string().required('Postcode is a required field.'), 
    postcode2: Yup.string().required('Please complete the postcode.') ,
    city: Yup.string().required('City is a required field.') 
};

export const checkoutValidationSchema = Yup.object().shape(schema);

