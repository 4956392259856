//import AliceCarousel from 'react-alice-carousel';
// import "react-alice-carousel/lib/alice-carousel.css";
import image1 from "../assets/gallery/image1.jpg"
import image2 from "../assets/gallery/image2.jpg"
import image3 from "../assets/gallery/image3.jpg"
import image4 from "../assets/gallery/image4.jpg"
import image5 from "../assets/gallery/image5.jpg"
import image6 from "../assets/gallery/image6.jpg"
import image7 from "../assets/gallery/image7.jpg"
import image8 from "../assets/gallery/image8.jpg"


import React, { useState, useEffect } from 'react'


const imgs = [image1, image2, image3, image4, image5, image6, image7, image8];
const delay = 2500;
export const PhotoGallery = () => {

    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);


    const resetTimeout=()=>{
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === imgs.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {
          resetTimeout();
        };
      }, [index]);


    return (

      <div className="slideshow">
      <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }} >
        {imgs.map((backgroundColor, index) => (
          <div className="slide" key={index} >
            <img src={backgroundColor} alt="" />
          </div>
        ))}
      </div>

      {/* <div className="slideshowDots">
        {colors.map((_, idx) => (
          <div
            key={idx}
            className={`slideshowDot${index === idx ? " active" : ""}`}
            onClick={() => {
              setIndex(idx);
            }}
          ></div>
        ))}
      </div> */}
    </div>
    )

}