import {Link} from "react-router-dom"

export const ErrorPayment = () => {

    return (
        <section className="checkout-container">
        
            <div className="checkout-header" style={{'marginTop':'3rem'}}>
                <div>
                    <h2 style={{color:'red'}}>Error..! Something wrong with your payment.</h2> 
                   
                    <p>Go to <Link className="menu-item" to="/payments" >Payment</Link> page.</p>                
                </div>
                <div>
                    {/* <p>Thank you for ordering with us..</p> */}
                </div>
            </div>
        </section> 
    )
}