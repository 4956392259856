//import { RiRegisteredLine } from 'react-icons/ri'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerValidationSchema } from "../validations/registerValidation"


type registerForm = {
    name: string,
    email: string,
    password: string,
    confirm_password: string    
};


export const Register = () => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<registerForm>({
        resolver: yupResolver(registerValidationSchema),
    });


    const handleChangeEvent = (event:any) => {
        const { name, value } = event.target;

        // if(name==='postcode'){
        //     let selected_postcode = postcodes.filter((item:any) => item.post_code == value);
        //     let delivery_charge = 0;
        //     if(selected_postcode.length>0){
        //         delivery_charge = selected_postcode[0]['delivery_charge'];
        //     } 
        //     setState((prevState:any) => ({
        //         ...prevState,
        //         ['delivery_charge']: delivery_charge
        //     }));
        //     errors.postcode = undefined;
        // }

        // setState((prevState:any) => ({
        //     ...prevState,
        //     [name]: value
        // }));

    }

    const submitCHeckout = (data:registerForm) => {
      
        

        // store shipping details into store
        // dispatch(addressActions.saveShippingAddress(data));
        // setDeliveryCharge(delivery_charge);
        // navigate('/payments');

    }

    return (
        <section className="checkout-container">
        
            <div className="checkout-header">
                <div>
                    <h2>Register</h2>                  
                </div>
                <div>
                    
                </div>
            </div>

            <div className="delivery-container">
                   
                <form className="form" id="register_form" onSubmit={handleSubmit(submitCHeckout)}>

                        
                        <div className="form-group hw">
                            <label htmlFor="">Name <span className="required">*</span></label>
                            <input 
                            type="text" 
                            className={`form-component ${errors.name ? 'is-invalid' : ''}`}
                            id="name"
                            {...register('name')}
                            onChange={handleChangeEvent} 
                            />
                            <div className="invalid-feedback">{errors.name?.message}</div>
                        </div>                       

                        <div className="form-group hw">
                            <label htmlFor="">Email <span className="required">*</span></label>
                            <input 
                            type="text" 
                            className={`form-component ${errors.email ? 'is-invalid' : ''}`}
                            id="email"
                            {...register('email')}
                            onChange={handleChangeEvent} 
                            />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>

                        <div className="form-group-container"> 
                            <div className="form-group hw">
                                <label htmlFor="">Password <span className="required">*</span></label>
                                <input 
                                type="password" 
                                className={`form-component ${errors.password ? 'is-invalid' : ''}`}
                                id="password"
                                {...register('password')}
                                onChange={handleChangeEvent} 
                                />
                                <div className="invalid-feedback">{errors.password?.message}</div>                             
                            </div>

                            <div className="form-group hw">
                                <label htmlFor="">Confirm Password <span className="required">*</span></label>
                                <input 
                                type="password" 
                                className={`form-component ${errors.confirm_password ? 'is-invalid' : ''}`}
                                id="confirm_password"
                                {...register('confirm_password')}
                                onChange={handleChangeEvent}                             
                                />
                                <div className="invalid-feedback">{errors.confirm_password?.message}</div>
                            </div>
                        </div>


                        <div className="form-group-container"> 
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <button className="btn" type="submit" >Submit</button>
                            </div>
                        </div> 


                </form>
                  
            </div>
        </section>    
    )
}
