export const ErrorOrder = () => {

    return (
        <section className="checkout-container">
        
            <div className="checkout-header" style={{'marginTop':'3rem'}}>
                <div>
                    <h2 style={{color:'red'}}>Error..! Something wrong with your order.</h2> 
                    <p>Your payment successfully completed but the order could not complete properly.</p> <br>
                    </br>
                    <h4>Please contact ChefofCeylon and verify.</h4>                
                </div>
                <div>
                    {/* <p>Thank you for ordering with us..</p> */}
                </div>
            </div>
        </section> 
    )
}