import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    additions: "",
    required_time: "",
    name: "",
    contact_no: "",
    email: "",
    address_line1: "",
    address_line2: "",
    house_number: "",
    postcode: "",
    postcode2: "",
    delivery_charge: "",
    city: "",
}

if(localStorage.getItem('address')) {  
    initialState = JSON.parse(localStorage.getItem('address') || '{}')
}else{
    initialState = {
        additions: "",
        required_time: "",
        name: "",
        contact_no: "",
        email: "",
        address_line1: "",
        address_line2: "",
        house_number: "",
        postcode: "",
        postcode2: "",
        delivery_charge: "",
        city: "",
    };
}



const addressSlice = createSlice({
    name : 'address',
    initialState,
    reducers: {

        saveShippingAddress(state,action){

            state.additions=action.payload.additions;
            state.required_time = action.payload.required_time;
            state.name = action.payload.name;
            state.contact_no = action.payload.contact_no;
            state.email = action.payload.email;
            state.address_line1 = action.payload.address_line1;
            state.address_line2 = action.payload.address_line2;
            state.house_number = action.payload.house_number;
            state.postcode = action.payload.postcode;
            state.postcode2 = action.payload.postcode2;
            state.delivery_charge = action.payload.delivery_charge;
            state.city = action.payload.city;
           
        },
        clearShippingAddress(state,action){}

    }
})

export const addressActions = addressSlice.actions;
export default addressSlice.reducer;