import React, { useState, useEffect } from 'react'
import API from '../networking/api'
import { TiShoppingCart } from "react-icons/ti"
import { Link } from "react-router-dom"
import { useDispatch,useSelector } from "react-redux"



import { OrderButton } from '../components/OrderButton'
import { CurrencyFormat } from '../components/CurrencyFormat'
import { loaderActions } from "../store/loader_slice"

export const RightPanel = () => {

    const [businessDays, setBusinessDays] = useState([]);
    const [cartActive, setCartActive] = useState(true);

    const cart = useSelector((state:any) => { return state.cart});

    const dispatch = useDispatch();

    type jdaysObj = {
        day: Date
        week_day: number
        ordered_qty: number
    }

    useEffect(() => {
        dispatch(loaderActions.activeLoader());
        API.get(`utils/get-business-days`)
        .then(res => {
            console.log(res);
            console.log(res.data);
            setBusinessDays(res.data.business_days);
            dispatch(loaderActions.inactiveLoader());
        }).
        catch(err => {
            console.log(err);
        });
        
        

    }, [])


    const showSideCart = () => {
        setCartActive(!cartActive);
    }


    return (
        <>
        <div className="order-buttons-container col-3">
                <div className="cart">
                    <TiShoppingCart />
                    {/* <a className="menu-item" onClick={showSideCart} ><CurrencyFormat amount={state.cart.total}/></a> */}
                    <Link className="menu-item" to={'cart'} ><CurrencyFormat amount={cart.total}/></Link>
                </div>
                <ul>
                {businessDays.map((itm:jdaysObj,i) => (
                     <li key={i}><OrderButton key={i} itm={itm} /></li>
                ))}
                </ul>
        </div>
        {/* <CartPanel is_active={cartActive}/> */}
        </>
    )
}