import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo.png'

import { MobileMenu } from '../components/MobileMenu'

export const Navbar = () => {

    const [mobileMenuActive, setMobileMenu] = useState(true);

    const showMobileMenu = () => {
        setMobileMenu(!mobileMenuActive);
    }


    return (
        <>
        <div className="top-menu-bar col-9">
            <div className='logo'>
                <Link to="/"><img src={Logo} /></Link>
            </div>
            {/* <div className="toggle_btn">
                <span></span>
                <span></span>
                <span></span>
            </div> */}
            {/* <Link className="menu-item" to="/login">Login | Register</Link> */}
            <div className="menu-items">
                <Link className="menu-item main" to="/" >Home</Link> 
                <Link className="menu-item" to="/reviews" >Reviews</Link> 
                <Link className="menu-item" to="/delivery" >Delivery</Link> 
                <Link className="menu-item" to="/gallery" >Gallery</Link> 
                <Link className="menu-item" to="/contact" >Contact</Link>
            </div>
            <div className="toggle_btn" onClick={showMobileMenu}>
                <span className="line"></span>
                <span className="line"></span>
                <span className="line"></span>
            </div>
            
            
        </div>

        <MobileMenu is_active={mobileMenuActive}/>
        </>

    )
}