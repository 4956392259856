//import { actionCreators, State } from '../state'
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from 'redux'


import { RiDeleteBinLine } from "react-icons/ri"
import { Link } from 'react-router-dom'
import { BiCreditCard } from 'react-icons/bi'
import { useState, useEffect } from 'react'
//import checkoutForm from '../forms/checkout/CheckoutForm'
import { checkoutValidationSchema } from "../validations/checkoutValidation"
import { cartActions } from "../store/cart_slice"
import { addressActions } from "../store/address_slice"



import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import API from '../networking/api'
import { CurrencyFormat } from '../components/CurrencyFormat';
import { json } from 'stream/consumers';



type checkoutForm = {
    additions: string,
    required_time: string,
    name: string,
    contact_no: string,
    email: string,
    address_line1: string,
    address_line2: string,
    house_number: string,
    postcode: string,
    postcode2: string,
    delivery_charge: number,
    city: string
};

export const Checkout = () => {

       
    const cart = useSelector((state:any) => { return state.cart});
    const address = useSelector((state:any) => { return state.address});
    
    const [postcodes, setPostCodes] = useState([]);
    const [deliveryCharge, setDeliveryCharge] = useState(0);


    const [delivery_address, setState] = useState(address);
   
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<checkoutForm>({
        resolver: yupResolver(checkoutValidationSchema),
    });


    const handleChangeEvent = (event:any) => {
        const { name, value } = event.target;

        if(name==='postcode'){

            let selected_postcode = postcodes.filter((item:any) => item.post_code == value);
            let delivery_charge = calcDeliveryCharge(selected_postcode[0]['post_code']);

            setState((prevState:any) => ({
                ...prevState,
                ['delivery_charge']: delivery_charge
            }));
            errors.postcode = undefined;
        }

        setState((prevState:any) => ({
            ...prevState,
            [name]: value
        }));

    }

    const calcDeliveryCharge = (postcode:string) => {

        let selected_postcode = postcodes.filter((item:any) => item.post_code == postcode);
        let delivery_charge = 0;

        console.log(selected_postcode);

        if(selected_postcode.length>0){

            if((postcode=='G13' || postcode=='G14' || postcode=='G15') && cart.total>=30){
                delivery_charge = 0;
            }else if((
                postcode=='G01' || 
                postcode=='G11' || 
                selected_postcode[0]['post_code']=='G12' ||
                selected_postcode[0]['post_code']=='G20' ||
                selected_postcode[0]['post_code']=='G52' ||
                selected_postcode[0]['post_code']=='G81'
                ) && cart.total>=40){
                    alert('g1');
                    delivery_charge = 0;
            }else{
                delivery_charge = selected_postcode[0]['delivery_charge'];
            }                 
        }

        //console.log(delivery_charge);
        setDeliveryCharge(delivery_charge);
        return delivery_charge;

    }


    const submitCHeckout = (data:checkoutForm) => {
      
        let selected_postcode = postcodes.filter((item:any) => item.post_code == data.postcode);
        // let delivery_charge = 0;
        // if(selected_postcode){
        //     delivery_charge = selected_postcode[0]['delivery_charge'];
        // }
        let delivery_charge = calcDeliveryCharge(selected_postcode[0]['post_code']);    
        data.delivery_charge = delivery_charge;

        // store shipping details into store
        dispatch(addressActions.saveShippingAddress(data));
        setDeliveryCharge(delivery_charge);
        navigate('/payments');

    }


    

    useEffect(() => {
        API.get(`utils/postcodes`)
        .then(res => {
            //console.log(res);         
            setPostCodes(res.data.postcodes);
            //alert(delivery_address.postcode);
            
           
        }).
        catch(err => {
            console.log(err);
        });


    }, [])


    useEffect(() => {
        calcDeliveryCharge(delivery_address.postcode);
    }, [postcodes])


    const setDeliveryAmount = (event:React.FormEvent<HTMLSelectElement>) =>{
        let postcode = event.currentTarget.value
        let selected_postcode = postcodes.filter((item:any) => item.post_code == postcode);
        let delivery_charge = 0;
        if(selected_postcode){
            delivery_charge = selected_postcode[0]['delivery_charge'];
        }    
        setDeliveryCharge(delivery_charge);
        errors.postcode = undefined;
    }

    
    return (
        <section className="checkout-container">
        
            <div className="checkout-header">
                <div className='checkout-header'>
                    <h2><BiCreditCard /> Checkout</h2>
                    <div className="total">Total :- <CurrencyFormat amount={cart.total}/></div>
                </div>
                <div>
                    {(cart.items.length>0)?               
                    <Link className="btn" to="/cart"><RiDeleteBinLine />Back to Cart</Link>:<p>Your cart is empty.</p>
                    }
                </div>
            </div>
            
            <div className="delivery-container">

                
                   
                    <form className="form" id="address_form" onSubmit={handleSubmit(submitCHeckout)}>

                        <div className="form-group">
                            <label htmlFor="">If you want more, less, or none of any ingredients which I am using on your selected menus, please indicate below so that I could avoid using them.</label>
                            <textarea 
                            className="form-component" 
                            id="additions" 
                            {...register('additions')} 
                            value={delivery_address.additions}
                            onChange={handleChangeEvent}                       
                            >
                            </textarea>
                        </div>
                        <div className="form-group">
                            <label htmlFor="">Required Time <span className="required">*</span></label>
                            <select 
                            className="form-component" 
                            id="required_time"
                            {...register('required_time')} 
                            value={delivery_address.required_time}
                            onChange={handleChangeEvent}  
                            >
                                <option value="5:00 PM - 5:30 PM">5:00 PM - 5:30 PM</option>
                                <option value="5:30 PM - 6:00 PM">5:30 PM - 6:00 PM</option>
                                <option value="6:00 PM - 6:30 PM">6:00 PM - 6:30 PM</option>
                                <option value="6:30 PM - 7:00 PM">6:30 PM - 7:00 PM</option>
                                <option value="7:00 PM - 7:30 PM">7:00 PM - 7:30 PM</option>
                                <option value="7:30 PM - 8:00 PM">7:30 PM - 8:00 PM</option>
                                <option value="8:00 PM - 8:30 PM">8:00 PM - 8:30 PM</option>
                                <option value="8:30 PM - 9:00 PM">8:30 PM - 9:00 PM</option>
                            </select>
                        </div>

                        <h4>Delivery Address</h4>

                        <div className="form-group-container"> 
                            <div className="form-group hw">
                                <label htmlFor="">Name <span className="required">*</span></label>
                                <input 
                                type="text" 
                                className={`form-component ${errors.name ? 'is-invalid' : ''}`}
                                id="name"
                                {...register('name')}
                                value={delivery_address.name}
                                onChange={handleChangeEvent} 
                                />
                                <div className="invalid-feedback">{errors.name?.message}</div>
                            </div>

                            <div className="form-group hw">
                                <label htmlFor="">Contact Number <span className="required">*</span></label>
                                <input 
                                type="text" 
                                id="contact_no"
                                className={`form-component ${errors.contact_no ? 'is-invalid' : ''}`}
                                {...register('contact_no')}
                                value={delivery_address.contact_no}
                                onChange={handleChangeEvent}
                                />
                                <div className="invalid-feedback">{errors.contact_no?.message}</div>
                            </div>
                        </div>


                        <div className="form-group hw">
                            <label htmlFor="">Email</label>
                            <input 
                            type="text" 
                            id="email"
                            className={`form-component ${errors.email ? 'is-invalid' : ''}`}
                            {...register('email')}
                            value={delivery_address.email}
                            onChange={handleChangeEvent}
                            />
                            <div className="invalid-feedback">{errors.email?.message}</div>
                        </div>


                        <div className="form-group-container"> 
                            <div className="form-group hw">
                                <label htmlFor="">Address Line1 (House number) <span className="required">*</span></label>
                                <input 
                                type="text" 
                                placeholder='Enter your house number' 
                                id="address_line1"
                                className={`form-component ${errors.address_line1 ? 'is-invalid' : ''}`}
                                {...register('address_line1')} 
                                value={delivery_address.address_line1}
                                onChange={handleChangeEvent}                             
                                />
                                <div className="invalid-feedback">{errors.address_line1?.message}</div>
                            </div>

                            <div className="form-group hw">
                                <label htmlFor="">Address Line2</label>
                                <input 
                                type="text" 
                                className="form-component" 
                                id="address_line2"
                                {...register('address_line2')} 
                                value={delivery_address.address_line2}
                                onChange={handleChangeEvent}
                                />
                            </div>
                        </div>

                        <div className="form-group-container"> 
                            <div className="form-group">
                                <label htmlFor="">Postcode <span className="required">*</span></label>
                                <select                                 
                                id="postcode"
                                className={`form-component ${errors.postcode ? 'is-invalid' : ''}`}                              
                                {...register('postcode')} 
                                value={delivery_address.postcode}
                                onChange={handleChangeEvent} 
                                >
                                    <option></option>
                                    {
                                      postcodes.map((code:any,i)=> (
                                        <option value={code.post_code} key={i}>{code.post_code}</option>
                                      )) 
                                    }
                                </select>
                                <div className="invalid-feedback">{errors.postcode?.message}</div>
                            </div>

                            <div className="form-group">
                                <label htmlFor="">&nbsp;</label>
                                <input 
                                type="text" 
                                className="form-component"  
                                id="postcode2"
                                {...register('postcode2')} 
                                value={delivery_address.postcode2}
                                onChange={handleChangeEvent}
                                />
                                
                            </div>

                            <div className="form-group">
                                <label >City</label>
                                <select 
                                className="form-component" 
                                {...register('city')} 
                                value={delivery_address.city}
                                onChange={handleChangeEvent}
                                >
                                    <option>Glasgow</option>
                                </select>
                            </div>


                            <div className="form-group">
                                <label>Delivery Charge</label>
                                <div className="form-component" style={{textAlign: "right",backgroundColor:"#f5f77c"}}>
                                    {(deliveryCharge>0)?deliveryCharge.toFixed(2):"0.00"}
                                </div>     

                                {/* <input 
                                type="text" 
                                className="form-component" 
                                //readOnly  
                                id="delivery_charge"
                                {...register('delivery_charge')} 
                                value={delivery_address.delivery_charge}
                                onChange={handleChangeEvent}
                                /> */}
                            </div>


                            

                           
                        </div>

                            

                        <div className="form-group-container"> 
                            <div className="form-group">
                                <label>&nbsp;</label>
                                <button className="btn" type="submit" ><BiCreditCard /> Continue For Payment</button>
                            </div>
                        </div>    

                       

                    </form>
                   


            </div>

        
        </section>
    )
}