//import { actionCreators, State } from '../state'
import { useDispatch, useSelector } from "react-redux"
import { bindActionCreators } from 'redux'

import { RiDeleteBinLine } from "react-icons/ri"
import { Link } from 'react-router-dom'
import { BiCreditCard } from 'react-icons/bi'
import { TiShoppingCart } from 'react-icons/ti'
import { cartActions } from "../store/cart_slice"
import { CurrencyFormat } from "../components/CurrencyFormat"
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL


export const Cart = () => {

   
    const cart = useSelector((state:any) => { return state.cart});
    console.log(cart);
    

    const dispatch = useDispatch();
    //dispatch(cartActions.clearCart());
    const removeProduct = (required_date:string,product_code:string) => {     
        const ar = {        
            required_date:required_date,
            product_code:product_code          
        }
        dispatch(cartActions.removeFromCart(ar));
    }

    const clearShoppingCart = () => {
        dispatch(cartActions.clearCart());
    }

    return (
        <section className="checkout-container">
            <div className="checkout-header col-12">
                <div className="cart-head">
                    <h2><TiShoppingCart /> Your Shopping Cart</h2>
                    <div className="total">Total :- <CurrencyFormat amount={cart.total}/> </div>
                </div>
                <div>
                    {(cart.items.length>0)?
                    <>
                    <a className="btn del" onClick={clearShoppingCart}><RiDeleteBinLine /> Clear Cart</a>               
                    <Link className="btn" to="/checkout" ><BiCreditCard /> Checkout</Link></>:<p>Your cart is empty.</p>}
                </div>
            </div>

            
            
            <div className="checkout-products">
            {cart.items.length>0 &&
            cart.items.map((cart:any,i:number) => (
                <div className="checkout-dates" key={i}>
                    <div className="checkout-date">On {cart.required_date}</div>
                    <div className="checkout-product-holder col-12">
                    {cart.products.map((prod:any,x:number) => (
                        <div className="product-box col-6" key={x}>
                            <div className="product">
                                <img src={`${BASE_URL}products/product_image/${prod.product_image}`} />
                                <div className="product-data">    
                                    <h4>{prod.product_name} - ({prod.product_code})</h4>
                                    <p>{prod.description}</p>
                                    <div className="product-footer">
                                        <p className="product-price">Price:- {prod.discount_price.toFixed(2)}, Quantity:- {prod.quantity}, Amount:- {(parseFloat(prod.quantity)*parseFloat(prod.discount_price)).toFixed(2)}</p>
                                        <h2 className="delete"><a title='Remove' onClick={() => removeProduct(cart.required_date,prod.product_code)} ><RiDeleteBinLine /></a></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>    
                </div>
            ))}
            </div>

        
        </section>
    )
}