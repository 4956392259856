import fb from '../assets/images/facebook.png'
import { BsPhone } from "react-icons/bs"
import { FiPhoneCall } from "react-icons/fi"
import { HiOutlineMail } from "react-icons/hi"

export const Footer = () => {

    return (

            <div className="footer-container">
                <div className="paypal-btn">
                <div className="paypal-powered">
                    <a href="https://www.paypal.com/webapps/mpp/paypal-popup" title="How PayPal Works" >
                        <img src="https://www.paypalobjects.com/digitalassets/c/website/marketing/na/us/logo-center/9_bdg_secured_by_pp_2line.png" alt="Secured by PayPal" />
                    </a>
                    &nbsp;
                    <a href="http://www.facebook.com/Chef-of-Ceylon-100187618831016/" target="_balank">
                        <img src={fb} alt="Facebook"  className="facebook"/>
                    </a>
                </div>
                </div>

                <div className='contact'>
                    <h4>Quick Contact</h4>
                    <div className='contact_item'><div className='icon_holder'><BsPhone className='icon'/></div> 0776 781 3268</div> 
                    <div className='contact_item'><div className='icon_holder'><FiPhoneCall className='icon'/></div> 0141 463 1780</div>
                    <div className='contact_item'><div className='icon_holder'><HiOutlineMail className='icon'/></div> orders@chefofceylon.co.uk</div>
                </div>    
            </div>
    )
}