import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth_slice";
import cartSlice from "./cart_slice";
import addressSlice from "./address_slice";
import loaderSlice from "./loader_slice";

const store = configureStore({
    reducer: {
        // auth: authSlice.reducer,
        // cart: cartSlice.reducer
        auth: authSlice.reducer,
        cart: cartSlice,
        address: addressSlice,
        loader: loaderSlice
    }

});

export default store;