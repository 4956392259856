export const SuccessOrder = () => {

    return (
        <section className="checkout-container">
        
            <div className="checkout-header" style={{'marginTop':'3rem'}}>
                <div>
                    <h2>Your order successfully completed.</h2> 
                    <p>Thank you for your order. If you have any questions, please do not hesitate to get in touch with me.</p>                 
                </div>
                <div>
                    {/* <p>Thank you for ordering with us..</p> */}
                </div>
            </div>
        </section> 
    )
}