
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState, useEffect } from 'react'
import API from '../networking/api'

import { contactValidationSchema } from "../validations/contactValidation";

import { BsPhone } from "react-icons/bs"
import { FiPhoneCall } from "react-icons/fi"
import { HiOutlineMail } from "react-icons/hi"



// import GoogleMapReact from 'google-map-react';
// const AnyReactComponent = ({ lat, lng,text }) => <div>{text}</div>;

import { useDispatch,useSelector } from "react-redux"
import { loaderActions } from "../store/loader_slice"


type contactForm = {
    first_name: string,
    second_name: string,
    email: string,
    address: string,
    postcode: string,
    phone: string,
    message: number
};


export const Contact = () => {

    const dispatch = useDispatch();


    const [contact_info, setState] = useState({});
    const [success, setMessage] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<contactForm>({
        resolver: yupResolver(contactValidationSchema),
    });


    const submitContact = (data:contactForm) => {
        //console.log(data);
            dispatch(loaderActions.activeLoader());
            //reset();
            API.post(`contact/save`,data)
            .then(res => {
              console.log(res);   
              if(res.data.status==='success'){
  
                //   dispatch(cartActions.clearCart());
                //   navigate('/success_order');
                reset();
                setMessage(true);
                dispatch(loaderActions.inactiveLoader()); 
  
              }else{
  
                  console.log(res.data.message);
              }          
                                
            }).catch(err => {
                console.log(err);
            });

    }


    const handleChangeEvent = (event:any) => {
        const { name, value } = event.target;

        setState((prevState:any) => ({
            ...prevState,
            [name]: value
        }));

    }


    const defaultProps = {
        center: {
          lat: 10.99835602,
          lng: 77.01502627
        },
        zoom: 11
    };

   

    return (
        <section className="checkout-container">
            <div className="checkout-header col-12">
                <div className="cart-head">
                    <h2>CONTACT</h2>
                    <div className="total"></div>
                </div>
            </div>


            <div className="contact-main col-12">
                <div className="delivery-container col-7">
                <form className="form" id="address_form" onSubmit={handleSubmit(submitContact)}>

                    <div className="form-group-container"> 
                        <div className="form-group hw">
                            <label htmlFor="">First Name <span className="required">*</span></label>
                            <input 
                            type="text" 
                            className={`form-component ${errors.first_name ? 'is-invalid' : ''}`}
                            id="first_name"
                            {...register('first_name')}
                            //value={delivery_address.name}
                            onChange={handleChangeEvent} 
                            />
                            <div className="invalid-feedback">{errors.first_name?.message}</div>
                        </div>

                        <div className="form-group hw">
                            <label htmlFor="">Second Name</label>
                            <input 
                            type="text" 
                            id="second_name"
                            className={`form-component ${errors.second_name ? 'is-invalid' : ''}`}
                            {...register('second_name')}
                            //value={delivery_address.second_name}
                            onChange={handleChangeEvent}
                            />
                            <div className="invalid-feedback">{errors.second_name?.message}</div>
                        </div>
                    </div>

                    <div className="form-group hw">
                        <label htmlFor="">Email <span className="required">*</span></label>
                        <input 
                        type="text" 
                        id="email"
                        className={`form-component ${errors.email ? 'is-invalid' : ''}`}
                        {...register('email')}
                        onChange={handleChangeEvent}
                        />
                        <div className="invalid-feedback">{errors.email?.message}</div>
                    </div>


                    <div className="form-group hw">
                        <label htmlFor="">Address</label>
                        <input 
                        type="text" 
                        id="address"
                        className={`form-component ${errors.address ? 'is-invalid' : ''}`}
                        {...register('address')}
                        onChange={handleChangeEvent}
                        />
                        <div className="invalid-feedback">{errors.address?.message}</div>
                    </div>


                    <div className="form-group-container">
                        <div className="form-group hw">
                            <label htmlFor="">Postcode</label>
                            <input 
                            type="text" 
                            id="postcode"
                            className={`form-component ${errors.postcode ? 'is-invalid' : ''}`}
                            {...register('postcode')}
                            onChange={handleChangeEvent}
                            />
                            <div className="invalid-feedback">{errors.postcode?.message}</div>
                        </div>

                        <div className="form-group hw">
                            <label htmlFor="">Phone <span className="required">*</span></label>
                            <input 
                            type="text" 
                            id="phone"
                            className={`form-component ${errors.phone ? 'is-invalid' : ''}`}
                            {...register('phone')}
                            onChange={handleChangeEvent}
                            />
                            <div className="invalid-feedback">{errors.phone?.message}</div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="">Message <span className="required">*</span></label>
                        <textarea 
                        className={`form-component ${errors.message ? 'is-invalid' : ''}`}
                        id="message" 
                        {...register('message')} 
                       // value={delivery_address.message}
                        onChange={handleChangeEvent}                       
                        >
                        </textarea>
                        <div className="invalid-feedback">{errors.message?.message}</div>
                    </div>


                    <div className="form-group-container">
                        <div className="form-group hw">
                            <label>&nbsp;</label>
                            <button className="btn" type="submit" >Send Message</button>
                          
                        </div>

                        <div className="form-group hw">
                        <label>&nbsp;</label>
                            {success && 
                                <p className='success'>Message Successfully Sent.</p>
                            }
                        </div>


                    </div>   


                    
                </form>
                </div>

                <div className="delivery-container address col-5" style={{'marginTop':'2rem'}}>
                    <h4>ADDRESS</h4>
                    No. 4, Linnet Place
                    Knightswood Gate
                    Glasgow,
                    G13 4QB.


                    <br/><br/>

                    <h4><BsPhone /> 0776 781 3268</h4> 
                    <h4><FiPhoneCall /> 0141 463 1780</h4>
                    <h4><HiOutlineMail /> orders@chefofceylon.co.uk</h4>



                    {/* <div className="map">

                        <div style={{ height: '300px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "" }}
                            defaultCenter={defaultProps.center}
                            defaultZoom={defaultProps.zoom}
                        >
                            <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                            />
                        </GoogleMapReact>
                        </div>

                    </div> */}


                </div>

            </div>

            <div className="col-12">
            
            </div>

            
        </section>    
    )
}