import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import { PayPalScriptOptions } from "@paypal/paypal-js/types/script-options";
import { PayPalButtonsComponentOptions } from "@paypal/paypal-js/types/components/buttons";
import { useSelector,useDispatch } from "react-redux";
import API from '../../networking/api'
import { useNavigate } from 'react-router-dom';
//import { useEffect } from 'react'


//import { actionCreators, State } from '../../state'

import { cartActions } from "../../store/cart_slice"
import { loaderActions } from "../../store/loader_slice"

const PAY_PAL_KEY = process.env.REACT_APP_PAYPAL_CLIENT_ID

const paypalScriptOptions: PayPalScriptOptions = {
  "client-id":""+PAY_PAL_KEY+"",
  currency: "GBP"
};
function Button() {
  /**
   * usePayPalScriptReducer use within PayPalScriptProvider
   * isPending: not finished loading(default state)
   * isResolved: successfully loaded
   * isRejected: failed to load
   */

  const cart = useSelector((state:any) => { return state.cart});
  const address = useSelector((state:any) => { return state.address});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  
  const [{ isPending }] = usePayPalScriptReducer();
  const paypalbuttonTransactionProps: PayPalButtonsComponentOptions = {
    style: { layout: "vertical" },
    createOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: (cart.total + address.delivery_charge)
            }
          }
        ]
      });
    },
    onApprove(data, actions:any) {
      /**
       * data: {
       *   orderID: string;
       *   payerID: string;
       *   paymentID: string | null;
       *   billingToken: string | null;
       *   facilitatorAccesstoken: string;
       * }
       */
      console.log(data);
      return actions.order.capture({}).then((details:any) => {

        const data_to_confirm = {
          cart : cart,
          shipping_address: address,
          paypal_order_id: data.orderID,
          payer_id: data.payerID
        }

        dispatch(loaderActions.activeLoader());
        API.post(`order/confirm_order`,data_to_confirm)
        .then(res => {
            console.log(res);   
            if(res.data.status==='success'){

                dispatch(cartActions.clearCart());
                dispatch(loaderActions.inactiveLoader());
                navigate('/success_order'); 

            }else{

                console.log(res.data.message);
                dispatch(cartActions.clearCart());
                dispatch(loaderActions.inactiveLoader());
                navigate('/error_order'); 
                
            }          
                              
        }).catch(err => {
            console.log(err);
        });

        // console.log(details);
        // alert(
        //   "Transaction completed by" +
        //     (details?.payer.name.given_name ?? "No details")
        // );

        // alert("Data details: " + JSON.stringify(data, null, 2));
      });
    }
  };
  return (
    <>
      {isPending ? <h2>Load Smart Payment Button...</h2> : null}
      <PayPalButtons {...paypalbuttonTransactionProps} />
    </>
  );
}


export const PayPal = () => {

  // const cart = useSelector((state:any) => { return state.cart});
  // const address = useSelector((state:any) => { return state.address});

  // useEffect(() => {

  //   const data = {
  //     cart : cart,
  //     shipping_address: address
  //   }
  //   API.post(`order/confirm_order`,data)
  //   .then(res => {
  //       console.log(res);
        
  //   }).catch(err => {
  //       console.log(err);
  //   });
    
  
  // }, [])


 

    return (
        <>
            {/* {JSON.stringify(address)}
            {JSON.stringify(cart)} */}
            {/* <p>Paypal</p> */}
            <PayPalScriptProvider options={paypalScriptOptions}>
                <Button />
            </PayPalScriptProvider>
          
        </>    
    )
}