import { createSlice } from '@reduxjs/toolkit'

let initialState = {
    isLoading: false
}

const loaderSlice = createSlice({
    name : 'loader',
    initialState,
    reducers: {
        activeLoader(state){
            state.isLoading = true;
           
        },
        inactiveLoader(state){
            state.isLoading = false;
        }
    }
})

export const loaderActions = loaderSlice.actions;
export default loaderSlice.reducer;