
import img1 from "../assets/gallery2/biryani_rice.jpg"
import img2 from "../assets/gallery2/butter_ghee_rice.jpg"
import img3 from "../assets/gallery2/chefofceylon1.jpg"
import img4 from "../assets/gallery2/chefofceylon2.jpg"
import img5 from "../assets/gallery2/chefofceylon3.jpg"
import img6 from "../assets/gallery2/chefofceylon4.jpg"
import img7 from "../assets/gallery2/chefofceylon5.jpg"
import img8 from "../assets/gallery2/chefofceylon6.jpg"
import img9 from "../assets/gallery2/chefofceylon7.jpg"
import img10 from "../assets/gallery2/chefofceylon8.jpg"
import img11 from "../assets/gallery2/chefofceylon9.jpg"
import img12 from "../assets/gallery2/chefofceylon10.jpg"
import img13 from "../assets/gallery2/chefofceylon11.jpg"
import img14 from "../assets/gallery2/chefofceylon12.jpg"
import img15 from "../assets/gallery2/chicken_buriyani.jpg"
import img16 from "../assets/gallery2/chicken_kottu.jpg"
import img17 from "../assets/gallery2/ddd.jpg"
import img18 from "../assets/gallery2/deligation.jpg"
import img19 from "../assets/gallery2/devil_chicken.jpg"
import img20 from "../assets/gallery2/devil_poteto.jpg"
import img21 from "../assets/gallery2/gal_pack1.jpg"
import img22 from "../assets/gallery2/gal_pack2.jpg"
import img23 from "../assets/gallery2/kottu.jpg"
import img24 from "../assets/gallery2/lamprais.jpg"
import img25 from "../assets/gallery2/lump1.jpg"
import img26 from "../assets/gallery2/lump2.jpg"
import img27 from "../assets/gallery2/lump3.jpg"
import img28 from "../assets/gallery2/sandw.jpg"
import img29 from "../assets/gallery2/shor_eats.jpg"
import img30 from "../assets/gallery2/tbl_food.jpg"
import { useState } from "react"
import { IoMdClose } from 'react-icons/io';


export const Gallery = () => {


    let data = [
        { id : 1, imgSRC : img1},
        { id : 2, imgSRC : img2},
        { id : 3, imgSRC : img3},
        { id : 4, imgSRC : img4},
        { id : 5, imgSRC : img5},
        { id : 6, imgSRC : img6},
        { id : 7, imgSRC : img7},
        { id : 8, imgSRC : img8},
        { id : 9, imgSRC : img9},
        { id : 10, imgSRC : img10},
        { id : 11, imgSRC : img11},
        { id : 12, imgSRC : img12},
        { id : 13, imgSRC : img13},
        { id : 14, imgSRC : img14},
        { id : 15, imgSRC : img15},
        { id : 16, imgSRC : img16},
        { id : 17, imgSRC : img17},
        { id : 18, imgSRC : img18},
        { id : 19, imgSRC : img19},
        { id : 20, imgSRC : img20},
        { id : 21, imgSRC : img21},
        { id : 22, imgSRC : img22},
        { id : 23, imgSRC : img23},
        { id : 24, imgSRC : img24},
        { id : 25, imgSRC : img25},
        { id : 26, imgSRC : img26},
        { id : 27, imgSRC : img27},
        { id : 28, imgSRC : img28},
        { id : 29, imgSRC : img29},
        { id : 30, imgSRC : img30}    
    ]


    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');


    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true)
    }


    return (

        <section className="checkout-container" >
            <div className="checkout-header col-12">
                <div className="cart-head">
                    <h2>PHOTO GALLERY</h2>
                    
                </div>
                
            </div>

            <div className={model? "model open" : "model"}>
                <img src={tempImgSrc} />
                <IoMdClose className="close" onClick={() => setModel(false)}/>
            </div>

            <div className="gallery_container">
                {data.map((item,index) => {
                    return (
                        <div className="pics" key={index} onClick={() => getImg(item.imgSRC)}>
                            <img src={item.imgSRC} style={{width: '100%' }}/>
                        </div>    
                    )

                })}
               

            </div>
        </section>    
    )
}