import { createSlice } from '@reduxjs/toolkit'


// type Action = {
//     type: string,
//     payload?: any
// }

// interface cartPayload {
//     items: any[],
//     delivery_cherge: number,
//     total: number
// }

const arr: any[] = [];
let initialState = {
    items: arr,
    delivery_cherge: 0,
    total: 0
}

if(localStorage.getItem('cart')) {  
    initialState = JSON.parse(localStorage.getItem('cart') || '[]')
}else{
    initialState.items = [];
}


const calcTotal = (items:any) => {

    let total = 0;
    Object.keys(items).filter(function(key) {
        Object.keys(items[key].products).filter(function(key2) {
            total += (parseInt(items[key].products[key2].quantity) * parseFloat(items[key].products[key2].discount_price))
        });
    });
    return total;
}

// const addToCart = (state:cartPayload,action:Action) => {

//     var payload = {}
//             if(state.items.length==0){
//                 payload = action.payload
//                 state.items.push(action.payload)
//             }else{
//                 var date_exists = 0; 
//                 Object.keys(state.items).filter(function(key:any) {

//                     if(state.items[key].required_date === action.payload.required_date){
//                         //console.log(state.cart[key].required_date);
//                         var exists = 0;
//                         Object.keys(state.items[key].products).filter(function(key2) {
//                             if(state.items[key].products[key2].product_code === action.payload.products[0].product_code){
//                                 state.items[key].products[key2].quantity += parseInt(action.payload.products[0].quantity);
//                                 exists++;
//                                 return
//                             }
//                         });

//                         if(exists==0){
//                             state.items[key].products.push(action.payload.products[0]);
//                         }

//                         date_exists++;
//                         return
//                         //state.cart[key].products.push(action.payload.products[0]);
//                     }                     
                   
//                 });

//                 if(date_exists==0){
//                     state.items.push(action.payload);
//                 }
//             }

//             state.total = calcTotal(state.items);
// }

const cartSlice = createSlice({
    name : 'cart',
    initialState,
    reducers: {

        addToCart(state,action){
           
            //console.log(action.payload)
            var payload = {}
            if(state.items.length==0){
                payload = action.payload
                state.items.push(action.payload)
            }else{
                var date_exists = 0; 
                Object.keys(state.items).filter(function(key:any) {

                    if(state.items[key].required_date === action.payload.required_date){
                        //console.log(state.cart[key].required_date);
                        var exists = 0;
                        Object.keys(state.items[key].products).filter(function(key2) {
                            if(state.items[key].products[key2].product_code === action.payload.products[0].product_code){
                                state.items[key].products[key2].quantity += parseInt(action.payload.products[0].quantity);
                                exists++;
                                return
                            }
                        });

                        if(exists==0){
                            state.items[key].products.push(action.payload.products[0]);
                        }

                        date_exists++;
                        return
                        //state.cart[key].products.push(action.payload.products[0]);
                    }                     
                   
                });

                if(date_exists==0){
                    state.items.push(action.payload);
                }
            }

            state.total = calcTotal(state.items);

        }
        ,
        removeFromCart(state,action){

            Object.keys(state.items).filter(function(key:any) {               
                if(state.items[key].required_date === action.payload.required_date){                                      
                   state.items[key].products = state.items[key].products.filter((item:any) => item.product_code !== action.payload.product_code);  
                }               
           });
          
           Object.keys(state.items).filter(function(key2:any) { 
               if(state.items[key2]){                                           
                   if(state.items[key2].products.length==0){
                   //state.cart.splice(key, 1);
                   state.items = state.items.filter((item:any) => item.required_date !== action.payload.required_date);  
                   } 
               }            
           });

           state.total = calcTotal(state.items);

        },
        addDeliveryCharge(state,action){
            state.delivery_cherge = action.payload.delivery_cherge;
            //state.total += state.delivery_cherge; 
        },
        clearCart(state){
            state.items = [];
            state.delivery_cherge = 0;
            state.total = 0;
        },
       
     },

})

export const cartActions = cartSlice.actions;
export default cartSlice.reducer;