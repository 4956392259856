

import { useSelector } from "react-redux"
import { BiCreditCard } from 'react-icons/bi'
import { IoIosArrowBack } from "react-icons/io"
import { Link } from 'react-router-dom'
//import PaypalCheckoutButton from "../components/buttons/PaypalCheckoutButton"
import { PayPal } from "../components/buttons/PayPal"
import { CurrencyFormat } from "../components/CurrencyFormat"

export const Peyments = () => {

    const cart = useSelector((state:any) => { return state.cart});
    const address = useSelector((state:any) => { return state.address});

    // useEffect(() => {   
    //    // localStorage.setItem('address',JSON.stringify(address));
    //    const script = document.createElement("script");
    //    script.src = "https://www.paypal.com/sdk/js?client-id=AR-iUf48w0PJfyOSWRArr9a4Te0I8qrtK9jlOjzBlRFs8ggsdX7MhutCLF_WWExj7AwABcQHV49iCHtp&currency=GBP";
    //    script.addEventListener("load", setLoaded);
    //    document.body.appendChild(script);


    // }, [])


    

    return (
        <>
            <div className="data-container">
                <div className="data-header">
                    <div>
                        <h2><BiCreditCard /> Payments</h2>
                        <div className="total">Total :- <CurrencyFormat amount={cart.total}/> </div>
                    </div>
                    <div>
                        {(cart.items.length>0)?               
                        <Link className="btn" to="/checkout"><IoIosArrowBack /> Back to Checkout</Link>:<p>Your cart is empty.</p>
                        }
                    </div>
                </div>
                <div className="cart-summery">
                    <div className="summery-header">
                        <h5>Your Shopping cart Summery.</h5>
                    </div>
                    <div className="summery-data">
                        <div className="summery-name">
                            Sub Total
                        </div>
                        <div className="summery-name">
                            <CurrencyFormat amount={cart.total}/>
                        </div>
                    </div>
                    <div className="summery-data">
                        <div className="summery-name">
                            Delivery Charge
                        </div>
                        <div className="summery-name">
                            <CurrencyFormat amount={address.delivery_charge}/>
                        </div>
                    </div>
                    <div className="summery-data">
                        <div className="summery-name">
                            Total
                        </div>
                        <div className="summery-name">
                            <b><CurrencyFormat amount={cart.total+address.delivery_charge}/></b>
                        </div>
                    </div>
                    <div className="summery-data">
                            <div className="payment-button">
                            
                            {(cart.items.length>0)?               
                                // <button className="btn" onClick={handlePayments}><BiCreditCard /> Confirm Payment</button>:<p></p>
                                // <PaypalCheckoutButton cart={cart} />:<p></p>
                                <PayPal />:<p></p>
                            }
                            </div>
                    </div>
                </div>
            </div>
        
        </>
    )
}

