export const Delivery = () => {
    return (

        <section className="checkout-container">
            <div className="checkout-header col-12">
                <div className="cart-head">
                    <h2>HOME DELIVERY INFORMATION</h2>
                    <div className="total"></div>
                </div>
            </div>


            <div className="col-6">
                <p>
                    Presently home delivery is available for the following postcodes only. Instead, you could take
away from our premises address given below
                </p>

                <br/><br/>

                <div style={{border:'2px solid #cccccc',padding:'2rem',borderRadius:'0.5rem'}}>
                <h4>G13 £3.00</h4>
                <h4>G14 £3.00</h4>
                <h4>G15 £3.00</h4>
                <br/>
                Free delivery for orders over £30.00 for all above postcode areas.
                </div>

                <br/>

                <div style={{border:'2px solid #cccccc',padding:'2rem',borderRadius:'0.5rem'}}>
                    <h4>G01 City Centre £5.00</h4>
                    <h4>G11 £5.00</h4>
                    <h4>G12 £5.00</h4>
                    
                    <h4>G20 £5.00</h4>
                    <h4>G52 £5.00</h4>
                    <h4>G81 £5.00</h4>
                    <br/>
                    <p>Free Delivery for orders over £40.00 all above postcodes.</p>
                </div>

                <br/>
                <br/>
                <br/>

                <p>We don’t deliver to other postcode area apart from above. Instead, you could take away from
                    our premises between 6.30pm and 9.00pm Monday to Friday</p>

                <br/>
              

                <h4>    
                    4 Linnet Place<br/>
                    Knightswood Gate<br/>
                    G13 4QB<br/>
                    Mobile: 0776 781 3268    
                </h4>

                <br/>
                <br/>
                <br/>
                <br/>


            </div>



        </section>    
    )
}