import axios from "axios";
//const BASE_URL = import.meta.env.VITE_BACKEND_API_URL
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-type': 'application/json'},
    withCredentials : true
});