
import { Link } from 'react-router-dom'


export const Login = () => {

    return (
        <section className="checkout-container">
        
            <div className="checkout-header">
                <div>
                    <h2>Login</h2>                  
                </div>
                <div>
                <Link className="menu-item" to="/register">Register</Link>
                </div>
            </div>
        </section>    
    )
}

