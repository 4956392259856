import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import { ProductItem } from '../components/ProductItem'
import { motion } from "framer-motion"
import { useDispatch,useSelector } from "react-redux"

import { loaderActions } from "../store/loader_slice"



import API from '../networking/api'



const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
};

const Products = () => {

    //const params = useParams();
    const {day} = useParams();
    const {week} = useParams();

   
    const [prod, setProducts] = useState([]);
    

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loaderActions.activeLoader());
        setProducts([]);
        //API.get(`products/product_by_business_date/${day}/${week}`)
        API.get(`products/get-products-by-day/${day}/${week}`)
        .then(res => {
            //console.log(day);
            console.log(res.data.day_product);
            
            //setProducts(res.data.day_product);
             setProducts(res.data.day_product)
             dispatch(loaderActions.inactiveLoader());
           
        }).
        catch(err => {
            console.log(err);
        });
    }, [day])



    const days_arr = [   
        { day: "Monday", day_num:1 },            
        { day: "Tuesday", day_num:2 },
        { day: "Wednesday", day_num:3 },
        { day: "Thursday", day_num:4 },
        { day: "Friday", day_num:5 }
    ]

    function getDateName(day_num:any){     
        var valObj = days_arr.filter(function(elem){
            if(elem.day_num == day_num) return elem.day;
        });   
        return valObj[0].day;    
    }


    
    

    return (
        <div style={{marginTop:'2rem'}}>
            <div className="selected-date col-12"><b>Available Menu Items on </b><span className='highlighter'>{day} - {getDateName(week)}</span></div>
            <motion.div 
            initial={{ y: "-100%" }}
            animate={{ y: "0%" }} 
            className="item-container">
            {/* <div className="item-container"> */}
            
            {prod.length>0 &&
                prod.map((product:any,i) => (
                    <ProductItem key={i} product={product} day={day} />
                ))}

            {/* </div> */}
            </motion.div>
            
        </div>
    )
}

export default Products;