import { AddToBag } from '../components/buttons/AddToBag'
import { motion } from "framer-motion"
import { CurrencyFormat } from "../components/CurrencyFormat"
const BASE_URL = process.env.REACT_APP_BACKEND_API_URL

const variants = {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 }
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
  };

export const ProductItem = (props:any) => {

    return (

        <motion.div 
        initial={{ x: "-200%" }}
        animate={{ x: "0%" }}
        className="col-4">
            <div className="menu-card" >
                <div className="card-header">
                {props.product.product_name}
                </div>
                <div className="menu-image">
                    <img src={`${BASE_URL}products/product_image/${props.product.product_image}`} />
                </div>
                <div className="menu-details">
                    <p className='description'>{props.product.description}</p>
                    {props.product.sub_products.length>0 &&
                        props.product.sub_products.map((sub_product:any,i) => (
                        <div className='sub-products' key={i} >{sub_product.subproduct_name}</div>
                    ))}

                    {props.product.more_info &&
                    <p className='extra'>{props.product.more_info}</p>
                    }

                    <h4 className="price">
                    <CurrencyFormat amount={props.product.discount_price}/>
                    </h4>
                </div>
                <hr className="line"></hr>

                <div className="card-footers">             
                    {/* <AddToBag product={props.product} required_date={props.day} stocks={props.product.stocks}/>                                               */}
                </div>

            </div>
        </motion.div>
        
    )
}