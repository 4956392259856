// import { loaderActions } from "../store/loader_slice"
// import { useSelector,useDispatch } from "react-redux";

export const PreLoader = () => {

    //const dispatch = useDispatch();
    //onst loader = useSelector((state:any) => { return state.loader});

    return (
        <div className="loader-container">
            <span className="loader"></span>
        </div>
    )

}